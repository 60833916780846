<template>
  <SiteMain>
    <PageLayout
        header
        mediaBlock
        introBlock
        :goBack="true"
        :pageTitle="pageTitle"
        :pageVideo="pageVideo"
        :pageIntro="pageIntro"
        :imgZoom="pageImage"
        :pageContent="pageContent"/>
    <GridSection class="phase--overview"
                 blockHeading
                 :headingSmall="$t('What does this new process look like?')"
                 flexDisplay>
      <PhaseBlock/>
    </GridSection>
    <CtaBlock v-if="cta != null" :title="cta.title" :text="cta.text" :buttonText="cta.button_text"
              :buttonLink="cta.button_url"/>
  </SiteMain>
</template>

<script>
import SiteMain from "@/components/SiteMain.vue";
import PageLayout from "@/components/PageLayout.vue";
import CtaBlock from "../components/CtaBlock";
import GridSection from "@/components/GridSection.vue";
import PhaseBlock from "@/components/PhaseBlock.vue";

export default {
  name: "Journey",
  components: {
    PhaseBlock, GridSection,
    SiteMain,
    PageLayout,
    CtaBlock,
  },
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    pageTitle() {
      return this.$store.state.cache.data[`our_journey${this.$i18n.locale}`]?.page?.title ?? '';
    },
    pageVideo() {
      return this.$store.state.cache.data[`our_journey${this.$i18n.locale}`]?.page?.video ?? '';
    },
    pageImage() {
      return this.$store.state.cache.data[`our_journey${this.$i18n.locale}`]?.page?.image ?? '';
    },
    pageIntro() {
      return this.$store.state.cache.data[`our_journey${this.$i18n.locale}`]?.page?.introduction ?? '';
    },
    pageContent() {
      return this.$store.state.cache.data[`our_journey${this.$i18n.locale}`]?.page?.body ?? '';
    },
    cta() {
      const data = this.$store.state.cache.data[`our_journey${this.$i18n.locale}`];
      return data?.page?.cta?.length > 0 ? data.page?.cta[0] : null;
    },
  },
  methods: {
    toggleImageZoom() {
      this.isImageZoom = !this.isImageZoom;
      if (this.isImageZoom) {
        window.document.body.style.overflowY = "hidden";
      } else {
        window.document.body.style.overflowY = "unset";
      }
    },
    async loadPage() {
      const key = `our_journey${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=our_journey`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss">
.phase--overview {
  padding: 56px 0;
  @include tablet-down {
    padding: 48px 0;
  }

  .phase-overview--heading {
    padding-bottom: 32px;
    width: 100%;
    color: white;
    @include tablet-down {
      padding-bottom: 32px;
    }
  }
}

.site-main {
  background: radial-gradient(100% 100% at 10.66% 0%, #00192d 0%, #000000 100%);
}
</style>
